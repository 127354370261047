<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import { marked } from "marked";
import VDisclaimer from "@magnit/core/src/containers/VDisclaimer/VDisclaimer.vue";

interface ISpecialOfferProps {
  image: string;
  title: string;
  text: string;
  size: "big" | "small";
  url: string;
  startDate: string;
  endDate: string;
  disclaimer?: string;
}

const props = defineProps<ISpecialOfferProps>();

const getDates = () => {
  const sDate = getDateFromYYYYMMDD(props.startDate);
  const eDate = getDateFromYYYYMMDD(props.endDate);
  // с 4 по 28 февраля 2023 - дата в одном месяце и годе
  // с 20 марта по 13 апреля 2023 - дата в разных месяцах
  // с 10 декабря 2022 по 20 февраля 2023 - в разных месяца и годах

  let sString = `${formatDateToDDMMMM(sDate)} ${sDate.getFullYear()}`;
  const eString = `${formatDateToDDMMMM(eDate)} ${eDate.getFullYear()}`;

  if (sDate.getFullYear() === eDate.getFullYear()) {
    if (sDate.getMonth() === eDate.getMonth()) {
      sString = String(sDate.getDate());
    } else {
      sString = formatDateToDDMMMM(sDate);
    }
  }

  return `с ${sString} по ${eString}`;
};
</script>

<template>
  <NuxtLink class="special-offer" :class="[`special-offer_${size}`]" :to="url">
    <div class="special-offer__image-wrapper">
      <NuxtImg
        class="special-offer__image"
        :src="image"
        :alt="title"
        loading="lazy"
      />
      <VDisclaimer :content="disclaimer" />
    </div>
    <div class="special-offer__description">
      <VText tag="div" :font="{ xs: 'title-medium', l: 'title-large' }">
        {{ title }}
      </VText>

      <VText
        class="special-offer__text"
        tag="div"
        :font="{
          xs: 'body-small-regular',
          l: 'body-large-regular',
        }"
      >
        <div v-html="marked(text)" />
      </VText>

      <VText
        v-if="size === 'small'"
        class="special-offer__dates"
        tag="div"
        color="secondary"
        :font="{
          xs: 'body-small-regular',
          l: 'caption-large-regular',
        }"
      >{{ getDates() }}
      </VText>
    </div>
  </NuxtLink>
</template>

<style lang="postcss">
.special-offer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;

  & .disclaimer {
    right: var(--pl-unit-x5);
    bottom: var(--pl-unit-x2);
  }

  &__image-wrapper,
  &__description {
    border-radius: 20px;
  }

  &__image-wrapper {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    will-change: transform;

    .special-offer_small & {
      height: 30%;
      padding-top: 0;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease-out;

    .special-offer:hover & {
      transform: scale(1.1);
    }
  }

  &__description {
    padding: 20px;
    background: #f3ece1;
    flex-grow: 1;
    transition: 0.3s ease-out;
    display: flex;
    flex-direction: column;

    .special-offer:hover & {
      border-bottom-left-radius: 0;
    }
  }

  &__text {
    margin-top: 4px;
  }

  &__dates {
    margin-top: auto;
  }
}

@media (--pl-viewport-from-m) {
  .special-offer {
    &__image-wrapper,
    &__description {
      border-radius: 24px;
    }
  }
}

@media (--pl-viewport-from-l) {
  .special-offer {
    &__image-wrapper,
    &__description {
      border-radius: 28px;
    }

    &__description {
      padding: 24px;
    }

    &__text {
      margin-top: 8px;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .special-offer {
    &__image-wrapper,
    &__description {
      border-radius: 32px;
    }

    & .disclaimer {
      right: var(--pl-unit-x6);
    }
  }
}
</style>
